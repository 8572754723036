.header {
  display: flex;
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;
  min-height: 56px;
  align-items: center;
}

.logo {
  width: 13rem;
  height: 4rem;
  text-align: center;
}

.middle {
  flex: 1;
  -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1;         /* OLD - Firefox 19- */
  -webkit-flex: 1;          /* Chrome */
  -ms-flex: 1;
}

.right {  
  flex: 1;
  -webkit-box-flex: 1;      /* OLD - iOS 6-, Safari 3.1-6 */
  -moz-box-flex: 1;         /* OLD - Firefox 19- */
  -webkit-flex: 1;          /* Chrome */
  -ms-flex: 1;
  text-align: left;
}

.left {
  text-align: left;
  padding: 0px 0px 0px 15px;
}

.item {
  font-size: 38px;
  display: inline-block;
}

.dealer-content {
  padding: 0px 15px;
}

.dealer-content {
  text-align: justify;
  text-justify: inter-word;
}

.heading-style {
  font-style: italic;
}

.contents a {
  display: block;
  font-weight: 600;
}

.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  color: #fff;
  background-color: #0071BC;
  box-shadow: 0px 0px 6px #00000029;
  border: none;
  cursor: pointer;
}

.show {
  display: block;
}

.hide {
  display: none;
}


.caret:before {
  content: '';
  position: absolute;
  top: 13px;
  left: 10px;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.copyright {
  font-size: 10px;
}

.body-padding{
  padding: 0px 6rem;
}
.service-number{
  background-color: #0071BC;
  font-weight: 600;
  color: white;
  font-size: 24px;
  float: right;
  text-align: center;
  width: 200px;
  height: 50px;
  line-height: 2;
}
.half{
  width: 50%;
}