/* The container */
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #0071bc;
    border-radius: 3px;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
 
  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
/* Create two equal columns that floats next to each other */
.column {
    float: left;
    width: 20%;
    height: 33px; /* Should be removed. Only for demonstration */
  }
  
  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
  }

  .row {
 margin-left: 113px;
  }

  .column-removed{
    float: "";
    width: "";
    height: ""; /* Should be removed. Only for demonstration */
  }

  input[type=text] , input[type=email] , textarea , select {
    border: 1px solid #0071bc;
   padding: 16px;
   box-shadow: 0px 3px #0071bc;
   border-radius: 5px ;
   font-size: 19px;
 }
 
 input[type=text]:focus , input[type=email]:focus , textarea ,select {
    outline: none !important;
      border: 1px solid #0071bc;
     box-shadow: 0px 3px #0071bc;
 }

.businessDetailsWrapper{
  display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;      /* TWEENER - IE 10 */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}
.businessDetails-item{
  width:  40%;
}

.content-margin {
    margin-left: 160px;
    margin-right: 128px;
}

.submit-button {
  border: 1px solid #0071bc;
  padding: 11px 33px 10px 34px;
  border-radius: 5px;
  font-size: 19px;
  color: #0071bc;
  margin: 2rem 0; 
  cursor: pointer;
}

.column-hundred{
  width: 95.4%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 2%;
}

.column-fifty {
  width: 48.3%;
  margin-left: 0.5%;
  margin-right: 1.2%;
  margin-bottom: 2%;
}

.column-fifty-input-text {
  width: 45%;
  margin-left: 0.5%;
  margin-right: 1.2%;
  margin-bottom: 2%;
}

.column-fifty-label {
  width: 48.3%;
  margin-left: 0.5%;
  margin-right: 1.2%;
  margin-bottom: -1%;
}

.column-thirty{
  width: 29%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 2%;
}

.column-fifteen{
  width: 75%;
  margin-left: 0.5%;
  margin-right: 10%;
  margin-bottom: 2%;
}
.label-margin{
 margin-right: 7%;
 
}

.column-ten-input-select{
  margin-bottom: 2%;
}

.aspect-text{
  margin-top: 9%;
  font-weight: bold;
}

.display-none{
  visibility: hidden;
  pointer-events: none;
}
.aspect-ratio {
  width: 20%;
}
.soe-label-name{
  font-weight: 600;
  font-size: 18px;
}
.submit-button:disabled {
  cursor:auto;
}

@media screen and (max-width: 600px) {
  .content-margin {
    margin-left: 10px;
    margin-right: 10px;
  }

  input[type=email], input[type=text], select, textarea {
    border: 1px solid #0071bc;
    padding: 16px;
    box-shadow: 0 3px #0071bc;
    border-radius: 5px;
    font-size: 16px;
    color: #505050;
  }

  .column-fifty-input-text {
    width: 100%;
  }

  .column-fifty, .column-fifty-input-text, .column-thirty, .column-fifteen {
    margin-left: 0%;
    margin-right: 1.2%;
    margin-bottom: 6%;
  }

  .column-thirty {
    width: 90%;
    margin-right: .5%;
  }

  .column-hundred {
    width: 90%;
    margin-left: .5%;
    margin-right: .5%;
    margin-bottom: 4%;
  }

  .column-fifty-input-text {
    width: 90%;
  }

  .main-div {
    display: flex;
    flex-wrap: wrap;
    /* margin: 20px 0; */
  }

  .aspect-ratio {
    width: 100%;
    margin-bottom: 2%;
    justify-content: flex-start;
  }

  .label-margin {
    margin-right: 0;
    display: flex;
    align-items: center;
    width: 33%;
    justify-content: center;
  }

  .column-ten-input-select {
    margin-bottom: 14%;
    width: 100%;
  }

  .column-fifty-label {
    width: 100%;
    margin-left: .5%;
    margin-right: 1.2%;
    margin-bottom: 2%;
    font-size: 18px;
  }

  input[type=email], input[type=text], select, textarea {
    border: 1px solid #0071bc;
    padding: 16px;
    box-shadow: 0 3px #0071bc;
    border-radius: 5px;
    font-size: 16px;
    color: #505050;
  }

  .row {
    margin-left: 0px;
  }

  .column {
    float: left;
    width: 90%;
    height: 33px;
  }

  .column-fifty {
    width: 100%;
  }

  .submit-button {
    border: 1px solid #0071bc;
    padding: 11px 33px 10px 34px;
    border-radius: 5px;
    font-size: 19px;
    color: #0071bc;
    margin: 2rem 0;
    cursor: pointer;
    background-color: white;
  }
  .hide-in-mob{
    display: none;
  }
  .aspect-text{
    margin-top: 1%;
  }
  .column-fifteen{
    width: 94%;
  }
  .display-none{
    display: none;
  }
  .soe-label-name{
    width: 100%;
    justify-content: flex-start;
    font-size: 16px;
  }
  .soe-start,.soe-end{
    width: 40%;
  }
  .soe-to{
    width: 20%;
  }
  .location-wrapper{
    width: 90%;
  }

  
  
}

.modal {
  font-size: 12px;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 15px;
  text-align: center;
  padding: 0px;
  justify-content: center;
    align-items: center;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
  font-size: 19px;
  margin-left: -8px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 7px 11px;
  line-height: 23px;
  right: -16px;
  top: -18px;
  font-size: 29px;
  background: #463a3a;
  border-radius: 18px;
  border: 1px solid #cfcece;
  color: white;
}

.popup-content {
  text-align: center;
  border-radius: 14px;
  border: 2.5px solid #0099ff;
}



.close-popup-button {
  border: 1px solid #0071bc;
    padding: 11px 18px 11px 18px;
    border-radius: 5px;
    font-size: 13px;
    color: #0071bc;
    /* margin: 2rem 0; */
    cursor: pointer;
}

#popup-2{
  border: 2.5px solid #0099ff;
}