.row-header {
    width: 100%;
    display: flex;
}

.diameter {
    width: 80%;
}

.price-section {
    display: contents;
}

.price-fields {
    display: contents;
}

.input-field {
    margin-right: 1rem;
}

.main-div {
    display: flex;
    margin: 20px 0px;
}
@media screen and (max-width: 600px) {
    .diameter {
        width: 100%;
    }
    .price-section {
        display: block;
    }
    
    .price-fields {
        display: block;
    }
}